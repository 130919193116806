.main__login {
    //background-size: cover;
    background-color: rgb(224, 219, 222);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        padding: 0em 0em 10px 0em;
        width: 130px;
    }

    .panel {
        background-color: rgb(255, 255, 255);
        border-radius: 1em;
        display:flex;
        min-height: 500px;
        //height: 500px;
        height: auto;
        z-index: 1;
        width: 900px;

        .form {
            padding: 0px 16px 0px 16px;
            width: 45%;
            height: 100%;
            
            .header {
                //---------------
                padding: 65px 0px 0px 16px;
                .title {
                    font-family: 'bembo-std-bold';
                    font-size: 18pt;
                    font-weight: 900;
                    color: rgb(55, 53, 54);
                }
    
                .info {
                    font-family: 'bembo';
                    font-size: 12pt;
                    color: rgb(107, 97, 121);
                    //margin: 0.5em 0em 2.5em 0em;
                    margin: 8px 0px 0px 0px;
                }
            }

            .message { 
                min-height: 34px;
                max-height: 100%;
                margin: 10px 0px;
                .msgError {
                    background-color:#ffdbdb;
                    border: 1px solid #ff5153;
                    border-radius: 5px;
                    padding: 10px;
                    margin: 0em 1em 0em 1em;
                    width: 321px;
                    justify-content: center;
                    align-items: center;
                    display: inline-flex;
                    font-family: 'bembo-std-semibold';

                    a {
                        margin-right: 15px;
                        padding: 0px 6px 0px 6px;
                        background-color: #ff4d50;
                        border-radius: 30px;
                        color:white;
                        font-size: 12px;
                        font-weight: 10px;
                        cursor: context-menu;
                        font-family: 'Verdana';
                    }
                }

                .msgSuccess {
                    background-color: #B6F5A6;
                    border: 1px solid #7EFA5E;
                    border-radius: 5px;
                    padding: 10px;
                    margin: 0em 1em 0em 1em;
                    width: 321px;
                    justify-content: center;
                    align-items: center;
                    display: inline-flex;
                    font-family: 'bembo-std-semibold';

                    label {
                        font-size: 14px;
                    }

                    a {
                        margin-right: 15px;
                        padding: 0px 6px 0px 6px;
                        background-color: #7EFA5E;
                        border-radius: 30px;
                        color:white;
                        font-size: 12px;
                        font-weight: 10px;
                        cursor: context-menu;
                        font-family: 'Verdana';
                    }
                }
            }

            .body {
                display: grid;
                margin: 0em 1em;

                .block-input { 
                    margin: 0em 0em 1em 0em;
                    label{
                        font-family: 'bembo-std-semibold';
                        font-weight: 600;
                        font-size: 10pt;
                    }

                    .ant-input {
                        height: 2.5em;
                        //margin: 0em 0em 1em 0em;
                        border-radius: 5px;
                        font-family: 'bembo';
                    }

                    p{
                        font-family: "bembo";
                        color:red;
                        font-size: 12px;
                    }
                }
                
                a {
                    color: rgb(107, 97, 121);
                    text-align-last: end;
                    text-decoration-line: underline;
                    font-size: 10px;
                    margin: 15px 0px 10px 10px;
                    font-family: 'bembo';
                }
            }
            
            .submit{ 
                margin: 22px 0px 0px 0px;
                width: 100%;
                justify-content: center;
                display: inline-flex;
                
                .ant-spin-nested-loading{
                    .ant-spin-spinning{
                        .anticon{
                            position: absolute;
                            //top: 40%;
                            left: 62%;
                            margin: -10px;
                            font-size:16px;
                        }
                    }
                }
                button {
                    background-color: rgb(107, 97, 121); 
                    color: white;
                    font-family: 'bembo';
                    width: 140px;
                    height: 36px;
                    border-radius: 8px;
                    box-shadow: 0px;
                    border: none;
                    cursor: pointer;
                }
                button:active {
                    background-color: rgb(107, 97, 121); 
                    color: white;
                    width: 140px;
                    height: 36px;
                    border-radius: 8px;
                    font-family: 'bembo';
                    transition: all 0.1s;
                    cursor: pointer;
                }

                .sub-cancel {
                    margin : 0 20px 0 -5px;
                }
            }
        }

        .image {
            width: 61%;
            border-radius: 0em 1em 1em 0em;
            //height: 100%;
            background-image: url('./../images/login.jpg');
            background-repeat: round;
            align-items: center;
            
            .panel {
                height: 100%;
                width: 100%;
                background-color: rgba(107, 97, 121, 0.9);
                border-radius: 0em 1em 1em 0em;
                color: white;
                display:block;

                .logo {
                    padding: 36px 0px 0px 36px;
                    align-self: flex-start;
                }

                .info {
                    justify-content: center;
                    align-items: center;
                    padding: 140px 0px 0px 36px;
                    label {
                        font-family: 'bembo-std-bold';
                        font-size: 20pt;
                    }
                    p {
                        font-family: 'bembo';
                        font-size: 14pt;
                    }
                }
            }
        }
    }

    @media(max-width: 950px){

        padding: 10% 0;
        height: 100%;
        >div { 
            flex-direction: column;
            min-width: 328px;
            display: flex;
            max-width: 60%;
            width: 100%;
            //margin: 0px;

            .panel {
                width: 100%;
                min-height: max-content;
                background-color: transparent;
                flex-direction: column-reverse;
    
                >.form {
                    border-radius: 0em 0em 1em 1em;
                    background-color: white;
                    padding-bottom: 20px;
                    width: 100%;

                    .header { padding: 15px 0 0 0; >.info { margin: 0; } }
                    .message { .msgError { margin: 0; width: 100%; } }
                    .body { margin: 0; }
                    .submit{ .ant-spin-nested-loading, button, button:active, .ant-spin-container { width: 100%; }  }
                }
    
                >.image {
                    width: 100%;
                    height: 200px;
                    background-size: cover;
                    background-position: 50%;
                    background-repeat: no-repeat;
                    border-radius: 1em 1em 0em 0em;
                    background-color: rgba(107, 97, 121, 0.9);

                    >.panel {
                        //background-color: transparent;
                        border-radius: 1em 1em 0em 0em;
                        height: 100%;
                        .logo { 
                            text-align-last: center;
                            margin-bottom: 14px;
                            width: 100%; 
                            padding: 0; 
                            >img { 
                                padding: 30px 0 10px 0; 
                                height: 77px;
                                width: auto;
                            }
                        }
                        .info { 
                            padding: 0 14px; 
                            > label { font-size: 14pt; } 
                        }
                    }
                }
            }
        }
    }
}

.grecaptcha-badge {
    z-index: 1;
}