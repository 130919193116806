@import './assets/styles/variables';

* {
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family !important;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-gray-dark;
}

.boxed {
  position: relative;
  margin: 0 auto;

  @media (min-width: $width-phone) {
    width: 540px;
  }

  @media (min-width: $width-tablet) {
    width: 720px;
  }

  @media (min-width: $width-desktop) {
    width: 960px;
  }

  @media (min-width: $width-large) {
    width: 1140px;
  }
}

a,
a:visited,
a:active,
a:hover {
  color: $color-primary;
}

strong {
  color: $color-gray-dark;
}

.button {
  display: inline-block;
  padding: 0.5em 2.8em;
  margin: 0.5em 0;
  color: $color-white !important;
  font-size: $font14;
  font-weight: 700;
  text-align: center;
  border: none !important;
  background-color: $color-primary;
  cursor: pointer;

  @media (min-width: $width-tablet) {
    width: auto;
    font-size: $font16;
  }

  @media (min-width: $width-desktop) {
    width: auto;
  }

  &.center {
    display: table;
    margin-left: auto;
    margin-right: auto;
  }

  &.full {
    width: 100%;
    display: block;
  }

  &.large {
    padding: 1.2em 2.2em;
  }

  &:disabled {
    background-color: $color-gray;
  }

  &.primary {
    background-color: $color-primary;
  }

  &.secondary {
    background-color: $color-secondary;
  }

  &.red {
    background-color: red;
  }

  &.white {
    background-color: $color-white !important;
    border: 1px solid $color-black !important;
    color: $color-black !important;
  }

}

.loading__router {
  padding: 10px;
}

